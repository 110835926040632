import { EVALUTION_REQUEST_ID_QUERY_PARAM } from '@/lib/constants';
import {
    ANALYSIS_REQUEST_ID_QUERY_PARAM_KEY,
    ANALYSIS_TYPE_QUERY_PARAM_KEY,
    ANALYST_THEME_NAME_QUERY_PARAM_KEY,
    INSTRUMENT_ANALYSIS_TYPE_QUERY_PARAM_KEY,
    PROMPT_QUERY_PARAM_KEY,
} from '@/lib/constants/analyst';
import { InstrumentType } from '@/types/instrument';
import { Nullable } from '@/types/nullable';
import { InstrumentUrlParams } from '@/types/page';

export interface DeepDiveUrlParams {
    instrumentId: string;
    requestId?: string;
    resultTheme?: string;
    themeName?: string;
    useNewUrl?: boolean;
}

export type GetCompanyPageUrlsType = InstrumentUrlParams & {
    evaluationPage?: boolean;
    expandedModulePagePath?: Nullable<string>;
    analystRequestId?: string;
    requestId?: Nullable<string>;
    resultTheme?: string;
    themeName?: string;
};

export const getDeepDiveUrl = ({
    instrumentId,
    requestId,
    resultTheme,
    themeName,
    useNewUrl = false,
}: DeepDiveUrlParams) => {
    const baseUrl = useNewUrl ? '/analyst/deep-dive' : '/analyst/company';
    const companyUrl = `${baseUrl}/${instrumentId}`;
    let fullUrl = companyUrl;

    if (resultTheme) {
        fullUrl = `${fullUrl}?q=${encodeURIComponent(resultTheme)}`;
    }

    if (requestId) {
        fullUrl = resultTheme
            ? `${fullUrl}&${ANALYSIS_REQUEST_ID_QUERY_PARAM_KEY}=${requestId}`
            : `${fullUrl}?${ANALYSIS_REQUEST_ID_QUERY_PARAM_KEY}=${requestId}`;
    }

    if (themeName) {
        fullUrl =
            resultTheme || requestId
                ? `${fullUrl}&n=${encodeURIComponent(themeName)}`
                : `${fullUrl}?n=${encodeURIComponent(themeName)}`;
    }
    return fullUrl;
};

const symbolSpecialChars = /[/]+/g;
export const cleanTickerExchangeSymbols = (symbol: string) => symbol.replace(symbolSpecialChars, '.');
export const getCompanyPageUrls = ({
    ticker,
    exchange,
    evaluationPage = false,
    expandedModulePagePath,
    analystRequestId,
    requestId,
    resultTheme,
    themeName,
}: GetCompanyPageUrlsType) => {
    let baseUrl = `/stock/${cleanTickerExchangeSymbols(ticker)}/${cleanTickerExchangeSymbols(exchange)}`;
    const queryString = [];

    if (evaluationPage) {
        baseUrl = `${baseUrl}/evaluation`;
    }

    if (expandedModulePagePath) {
        baseUrl = `${baseUrl}/details/${expandedModulePagePath}`;
    }

    if (resultTheme) {
        queryString.push(
            `${PROMPT_QUERY_PARAM_KEY}=${encodeURIComponent(
                resultTheme
            )}&${ANALYSIS_TYPE_QUERY_PARAM_KEY}=${INSTRUMENT_ANALYSIS_TYPE_QUERY_PARAM_KEY}`
        );
    }

    if (themeName) {
        queryString.push(`${ANALYST_THEME_NAME_QUERY_PARAM_KEY}=${encodeURIComponent(themeName)}`);
    }

    if (analystRequestId) {
        queryString.push(`${ANALYSIS_REQUEST_ID_QUERY_PARAM_KEY}=${encodeURIComponent(analystRequestId)}`);
    }

    if (requestId) {
        queryString.push(`${EVALUTION_REQUEST_ID_QUERY_PARAM}=${requestId}`);
    }

    return queryString.length > 0 ? `${baseUrl}?${queryString.join('&')}` : baseUrl;
};

export const getTopFundamentals = (instrument?: InstrumentType) => {
    if (!instrument) return [];

    const businessFundamentals = instrument.businessFundamentals.filter(({ name }) => name === 'price_earnings_ratio');
    const tradingFundamentals = instrument.tradingFundamentals.filter(
        ({ name }) => name === 'market_capitalization_usd'
    );

    return [...businessFundamentals, ...tradingFundamentals];
};
